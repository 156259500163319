<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">

    <BAlert variant="danger" dismissible :show="machineId===null">
      연결 장비가 없습니다
    </BAlert>

    <BRow class="mb-3" v-if="machineId===null && onlyMachine===false">
      <BCol>
        <BInputGroup size="sm">
          <BFormSelect size="sm"
                       id="machines"
                       v-model="selectedMachineId"
                       @input="getMachineInfo(true)"
                       :options="machineOpts"
          />
          <BButton size="sm" variant="primary"
                   @click="linkMachine"
                   :disabled="selectedMachineId===null">
            장비 연결
          </BButton>
          <BButton size="sm" variant="warning" class="ml-3"
                   v-if="false"
                   @click="createMachine"
                   >
            장비 신규 생성
          </BButton>
        </BInputGroup>

      </BCol>
    </BRow>



    <div v-if="machine!==null">
    <!-- 장비정보 입력폼 --------------------------------------------------------------------------------------------->
      <BRow class="mb-1">
        <BCol>
          <BRow>
            <BCol>
              <BInputGroup size="sm" prepend="장비 ID">
                <BFormInput size="sm"
                            class="bg-warning"
                            id="mid"
                            type="text"
                            :state="isValidExp(machine.mid, 'hex', 4)"
                            v-model="machine.mid"
                            maxLength="4"
                            @input="v=>(machine.mid = v.toUpperCase())"
                            required/>


                <BButtonGroup class="ml-1">
                  <BButton size="sm" variant="danger" disabled>탱크</BButton>
                  <BButton size="sm" variant="outline-danger" disabled>
                    {{(machine.tank? `${machine.tank.tid} | ${machine.tank.name}`: '미설치')}}
                  </BButton>
                </BButtonGroup>

                <BButton size="sm"
                         v-show="machineId!==null && tankId!==null"
                         variant="primary" class="ml-2" @click="unlinkMachine">장비 연결 해제</BButton>
              </BInputGroup>

              <BTooltip target="mid"
                        variant="danger"
                        :show="!(isValidExp(machine.mid, 'hex')&&machine.mid.length===4)"
                        placement="right" triggers="manual">
                16진수 4자 입력
              </BTooltip>
            </BCol>

          </BRow>

        </BCol>
      </BRow>

      <BRow class="mb-1">
        <BCol>
          <BRow>
            <BCol>
              <BInputGroup size="sm" prepend="장비명">
                <BFormInput size="sm" id="mName" type="text"
                            v-model="machine.name"
                            maxLength="100"
                            :state="(machine.name.length>0)"/>
                <BTooltip target="mName"
                          variant="danger"
                          :show="machine.name.length===0"
                          placement="right" :triggers="['manual']">
                  탱크이름 필수 입력
                </BTooltip>
              </BInputGroup>
            </BCol>
            <BCol>
              <BInputGroup size="sm" prepend="장비종류">
                <BFormSelect size="sm"
                             id="mType"
                             :state="machine.type!==''"
                             v-model="machine.type"
                             :options="opts['MACHINE_TYPE']"
                             required/>
                <BTooltip target="mType"
                          variant="danger"
                          :show="machine.type.length===0"
                          placement="top" :triggers="['manual']">
                  장비종류 필수 입력
                </BTooltip>
              </BInputGroup>
            </BCol>
          </BRow>
        </BCol>
      </BRow>

      <BRow class="mb-1">
        <BCol>
            <BInputGroup size="sm" prepend="일련번호">
              <BFormInput size="sm" id="serialNo" type="text" maxLength="50"
                          v-model="machine.serialNo"/>


              <BInputGroupAppend size="sm" is-text>장비버전</BInputGroupAppend>

              <BFormInput size="sm" id="version" type="text" maxLength="50"
                          v-model="machine.version"/>


            </BInputGroup>
        </BCol>
      </BRow>

      <BRow class="mb-2">
        <BCol>
          <BInputGroup size="sm" prepend="제품명">
            <BFormInput size="sm" id="modelName" type="text" maxLength="50"
                        v-model="machine.productName"/>

            <BInputGroupAppend size="sm" is-text>모델명</BInputGroupAppend>
            <BFormInput size="sm" id="modelName" type="text" maxLength="50"
                        v-model="machine.modelName"/>

            <BInputGroupAppend size="sm" is-text>제조사</BInputGroupAppend>
            <BFormInput size="sm" id="company" type="text" maxLength="50"
                        v-model="machine.company"/>
          </BInputGroup>
        </BCol>
      </BRow>

      <BCard header="장비 연결정보" class="mb-1 m-1 p-1" no-body>
        <BRow class="mb-1">
          <BCol>
            <BInputGroup size="sm" prepend="연결장비ID">
              <BFormSelect size="sm"
                           id="linkMid"
                           :state="machine.linkMid!==''"
                           v-model="machine.linkMid"
                           :options="linkMidOpts"
                           required/>
            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup size="sm" prepend="연결형태">
              <BFormSelect size="sm"
                           id="linkType"
                           :state="machine.linkType!==''"
                           v-model="machine.linkType"
                           :options="opts['DATALINK_TYPE']"
                           required/>

            </BInputGroup>
          </BCol>
        </BRow>

        <BRow>
          <BCol>
            <BInputGroup size="sm" prepend="연결장비타입">
              <BFormSelect size="sm"
                           id="linkMchType"
                           :state="machine.linkMchType!==''"
                           v-model="machine.linkMchType"
                           :options="opts['MACHINE_TYPE']"
                           required/>
            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup size="sm" prepend="패킷종류">
              <BFormSelect size="sm"
                           id="packetType"
                           :state="machine.packetType!==''"
                           v-model="machine.packetType"
                           :options="opts['PACKET_TYPE']"
                           required/>
            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup size="sm" prepend="장비IP">
              <BFormInput size="sm" id="deviceIp" type="text" maxLength="50"
                          v-model="machine.deviceIp"/>

            </BInputGroup>
          </BCol>
        </BRow>

      </BCard>

      <BCard header="센서정보" class="m-1 p-1" no-body>
        <BRow class="mt-1">
          <BCol>
            <BInputGroup size="sm" prepend="센서타입">
              <BFormSelect size="sm"
                           id="sensorType"
                           :state="machine.sensorType!==''"
                           v-model="machine.sensorType"
                           :options="opts['SENSOR_TYPE']"
                           required/>

            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup prepend="센서길이(㎜)" size="sm">
              <BFormInput size="sm" id="sensorLen" type="number"
                          v-model="machine.sensorLen"
                          :step="1" min="1" max="15000"
                          :state="machine.sensorLen>0"/>
            </BInputGroup>

            <BTooltip target="sensorLen"
                      variant="danger"
                      :show="!machine.sensorLen>0"
                      placement="top" :triggers="['manual']">
              센서 길이(mm) 입력
            </BTooltip>
          </BCol>
        </BRow>

        <BRow class="mt-1">
          <BCol>
            <BInputGroup prepend="센서크기" size="sm" >
              <BFormSelect size="sm"
                           id="pipeType"
                           :state="machine.pipeType!==''"
                           v-model="machine.pipeType"
                           :options="opts['PIPE_TYPE']"
                           required/>

            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup prepend="체결길이(㎜)" size="sm">
              <BFormInput size="sm" id="joinLength" type="number"
                          v-model="machine.joinLength"
                          :step="1" min="1" max="15000"
                          :state="machine.joinLength>0"/>
            </BInputGroup>
          </BCol>
        </BRow>

        <BRow class="mt-1">
          <BCol>
            <BInputGroup prepend="Gain 값" size="sm" >
              <BFormInput size="sm" id="gainValue" type="number"
                          v-model="machine.gainValue"
                          :step="0.001" min="0.001" max="0.999"
                          :state="machine.gainValue>0"/>
            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup prepend="수분센서(㎜)" size="sm">
              <BFormInput size="sm" id="sensorWater" type="number"
                          v-model="machine.sensorWater"
                          :step="1" :min="1" :max="500"
                          :state="machine.sensorWater>0"/>
            </BInputGroup>
          </BCol>
        </BRow>
      </BCard>

      <BCard class="mb-1">
        <template #header>
          플로우트 보정
        </template>

        <BRow class="mt-1">
          <BCol>
            <BInputGroup prepend="오일(mm)" size="sm" >
              <BFormInput size="sm" id="oilRevBase" type="number"
                          v-model="machine.oilRevBase"
                          :step="1" min="0" max="9999"
              />
            </BInputGroup>
          </BCol>
          <BCol>
            <BInputGroup prepend="수분(mm)" size="sm" >
              <BFormInput size="sm" id="wtrRevBase" type="number"
                          v-model="machine.wtrRevBase"
                          :step="1" min="0" max="9999"
              />
            </BInputGroup>
          </BCol>
        </BRow>

      </BCard>


      <BCard class="mb-1">
        <template #header>
          온도센서 및 보정 설정
          <div class="card-header-actions">
            <BInputGroup prepend="센서갯수" size="sm">
            <BFormInput size="sm" id="numOfTemp" type="number" class="mb-0"
                        v-model="machine.numOfTemp"
                        step="1" :min="machine.temps.length" max="100"
                        @input="setTemps"
                        :state="machine.numOfTemp>0"/>
            </BInputGroup>
          </div>
        </template>

        <BTableSimple small class="mb-1">
          <BTr class="text-center">
            <BTh>번호</BTh><BTh>위치</BTh><BTh>보정</BTh><BTh>상태</BTh><BTh>삭제</BTh>
          </BTr>
          <BTr v-for="t in machine.temps" :key="t.idx">
            <BTd class="text-center">{{ t.idx }}</BTd>
            <BTd>
              <BFormInput v-model="t.location" size="sm" type="number" step="1" min="1" max="150000" class="small"/>
            </BTd>
            <BTd class="text-right">
              <BFormInput v-model="t.rev" size="sm" type="number" step="0.1" min="0" max="100" class="small"/>
            </BTd>
            <BTd class="text-center">{{ t.status }}</BTd>
            <BTd class="text-center">
              <BButton size="sm" variant="danger" @click="setTempsDel(t.idx)"><BIconTrashFill/></BButton>
            </BTd>
          </BTr>
        </BTableSimple>
        <BInputGroup prepend="유효 온도계 보정높이(mm)"
                     size="sm">
          <BFormInput size="sm" id="tempSinkRev" type="number" class="mb-0"
                      v-model="machine.tempSinkRev"
                      step="1" :min="0" max="1000"
                      :state="machine.tempSinkRev > 0"/>
        </BInputGroup>
        <span class="small text-info float-right">
            유위보다 <strong class="text-danger">{{machine.tempSinkRev}} mm</strong> 이하로 잠긴 온도센서를 유효 온도로 간주합니다.
        </span>

      </BCard>

      <BCard class="mb-1">
        <template #header>
          구간 보정 설정
          <div class="card-header-actions">
            <BInputGroup prepend="구간보정 수" size="sm">
              <BFormInput size="sm" id="numOfTemp" type="number" class="mb-0"
                          v-model="machine.numOfRev"
                          step="1" :min="machine.revisions.length" max="100"
                          @input="setRevs"
                          />
            </BInputGroup>
          </div>
        </template>

        <BTableSimple small class="mb-0">
          <BTr class="text-center">
            <BTh>번호</BTh><BTh>시작위치</BTh><BTh>종료위치</BTh><BTh>보정값(㎜)</BTh><BTh>삭제</BTh>
          </BTr>
          <BTr v-for="r in machine.revisions" :key="r.idx">
            <BTd class="text-center">{{ r.idx }}</BTd>
            <BTd>
              <BFormInput v-model="r.from" size="sm" type="number" step="1" min="1" max="150000" class="small"/>
            </BTd>
            <BTd>
              <BFormInput v-model="r.to" size="sm" type="number" step="1" min="1" max="150000" class="small"/>
            </BTd>
            <BTd class="text-right">
              <BFormInput v-model="r.rev" size="sm" type="number" step="1" min="-1000" max="1100" class="small"/>
            </BTd>
            <BTd class="text-center">
              <BButton size="sm" variant="danger" @click="setRevsDel(r.idx)"><BIconTrashFill/></BButton>
            </BTd>
          </BTr>
        </BTableSimple>
      </BCard>

      <BRow class="mb-3">
        <BCol>
          <BBadge pill variant="info">등록</BBadge><span class="text-muted small"> {{`${toLocalTime(machine.createdAt)} | ${machine.regId?machine.regId:'NOBODY'}`}} </span>
        </BCol>
        <BCol class="text-right">
          <BBadge pill variant="primary">수정</BBadge><span class="text-muted small"> {{`${toLocalTime(machine.updatedAt)} | ${machine.updId?machine.updId:'NOBODY'}`}} </span>
        </BCol>
      </BRow>



      <BRow>
        <BCol>
          <b-button class="mt-1" v-b-toggle.row-machine size="sm">원시 데이터 보기</b-button>
        </BCol>
        <BCol>
          <BButton block variant="danger" @click="saveMachine" :disabled="isUpdatingRecord">
            <BIconSave2Fill/> <strong>장비정보 저장</strong>
            <div class="sk-plane bg-light float-left" style="height:25px;width:25px" v-if="isUpdatingRecord"></div>
            <div class="sk-swing float-right" style="height:25px;width:25px" v-if="isUpdatingRecord" >
              <div class="sk-swing-dot"></div>
              <div class="sk-swing-dot"></div>
            </div>
          </BButton>

        </BCol>
      </BRow>


      <b-collapse id="row-machine" class="mt-2">
        <BTable small class="small" stacked :items="[machine]" :fields="machineFields">
          <template #cell(rowData)="data">
            <pre>{{data.item}}</pre>
          </template>
        </BTable>
      </b-collapse>


    </div>

  </div>
</template>

<style src="spinkit/spinkit.min.css"></style>

<script>
//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall,
  cloneVar, getRandomHex, sleep,
} from '@/common/utils';
import moment from "moment";
import {MachineSchema} from "@/common/schema";


//----------------------------------------------------------------------------------------------------
export default {
  name: "MachineForm",
  components: {},
  props: {
    machineId: { type: String, default: null },
    tankId: { type: String, default: null },
    onlyMachine: { type: Boolean, default: false },
  },
  watch:{
    machineId: function(newVal, oldVal){
      console.log('[props] machineId changed: ', newVal, ' | was: ', oldVal);
    }
  },

  data () {
    return {
      maps: this.$store.state.codeMaps,
      opts: this.$store.state.codeOpts,
      machine: null,
      pickerShow: false,
      machineOpts: [],
      selectedMachineId: null,

      originMachine: null,

      arCodeOpts  : [],
      linkMidOpts : [],

      machineFields: [
        {key: 'rowData', label: ''}
      ],

      isUpdatingRecord: false,

    }

  },
  async created(){
    console.log("--- MachineForm --- created---------------------");
    try{
      if(this.machineId) await this.getMachineInfo();
      else await this.getMachineOpts();

    }catch(err){
      console.log(err);
    }
  },
  computed: {},

  mounted() {
    console.log("--- MachineForm --- mounted---------------------");
    // below is not work!
  },

  methods: {
    setFromDate(){
      this.toDate =  moment(this.fromDate).add(1,"month").subtract(1,'day').format("YYYY-MM-DD");
    },

    setTemps(){
      let toCnt = this.machine.numOfTemp;
      if(!toCnt) return;

      let temps = [];
      if( !this.machine.temps ) this.machine.temps = [];

      console.log( 'npmOfTemp ----> ', toCnt );
      let idx = 1;

      for( let t of this.machine.temps ){
        temps.push( {idx: idx, location: t.location, rev: t.rev, status: t.status } );
        idx++;
      }


      for(let i=idx; i<=toCnt; i++){
        console.log( 'i-->', i );
        temps.push( {idx: i, location: 0, rev: 0, status: 'NA' });
      }

      this.machine.temps = temps;
      console.log( 'temps array ------> ', this.machine.temps );

    },

    setTempsDel( idx ){
        this.machine.temps = this.machine.temps.filter(i=> {
          return i.idx !== idx
        });
        this.machine.numOfTemp = this.machine.temps.length;
        this.setTemps();
    },


    setRevs(){
      let toCnt = this.machine.numOfRev;
      if(!toCnt) return;

      let revs = [];
      if( !this.machine.revisions ) this.machine.revisions = [];
      console.log( 'npmOfTemp ----> ', toCnt );

      let idx = 0;
      for( let r of this.machine.revisions ){
        revs.push( {idx: idx, from: r.from, to: r.to, rev: r.rev } );
        idx++;
      }

      for(let i=idx; i<toCnt; i++){
        console.log( 'i-->', i );
        revs.push( {idx: i, from: 0, to:0, rev: 0});
      }
      this.machine.revisions = revs;
      console.log( 'revisions array ------> ', this.machine.revisions );

    },

    setRevsDel( idx ){
      this.machine.revisions = this.machine.revisions.filter(i=> {
        return i.idx !== idx
      });
      this.machine.numOfRev = this.machine.revisions.length;
      this.setRevs();
    },


    async getMachineInfo(select=false){
      try{
        let objectId;
        if(select) objectId = this.selectedMachineId;
        else objectId = this.machineId;

        if(!objectId) {
          this.machine = null;
          this.originMachine = null;
          return
        }

        console.log("getMachineData machine._id --------->", objectId);
        const r = await apiCall('get', `/api/machine/${objectId}`);
        if(r.code!=200){
          return await this.alertDanger('NOT_FOUND: '+objectId )
        }

        if(select){
          // this.selectedMachineId = r.result;
          this.machine = r.result;
        }else{
          this.machine = r.result;
          this.originMachine = cloneVar(r.result);
        }
        console.log( 'getTankInfo result ---> select---', select, '---info---',r.result );

      }catch(err){
        console.error("getTaskData error", err);
      }
    },

    async getMachineOpts(){
      console.log("getMachineOptions---------");
      const {result} = await apiCall('get', `/api/machine`);
      if( result ){
        this.machineOpts = [ {text: '연결 장비 선택', value: null} ];
        for(let m of result){
          console.log( 'machine ---> ', m );
          if(m.tank===null)
            this.machineOpts.push({text:`[${m.mid}] ${m.name} / ${m.modelName}`, value:m._id} );
        }
        this.selectedMachineId = null;
        console.log( 'machine options --->', this.machineOpts);
      }

      console.log( 'getTankInfo result --->', result );
    },

    async linkMachine(){
      try {
        if (!await this.confirmModal(`장비 '${this.machine.mid}'를 연결합니다. 진행하시겠습니까?`, '장비 연결')) {
          return await this.alertModal('장비 연결이 취소되었습니다', '장비정보', 'warning');
        }

        const tankParam = {
          machine: this.machine._id,
          mid: this.machine.mid
        }

        const machineParam = {
          tank: this.tankId
        }

        const tRs = await apiCall('put', `/api/tank/${this.tankId}`, tankParam);
        console.log('linkMachine result ---------------tank---->',tRs);
        if (tRs.code === 200) {
          const mRs = await apiCall('put', `/api/machine/${this.machine._id}`, machineParam);
          this.$emit('update:machineId', mRs.result._id);
          // this.machineId = this.machine._id
          console.log('linkMachine this.machineId---->',this.machineId)
          this.machine = mRs.result;
        } else {
          await this.alertDanger('장비 연결 실패: ' + tRs.message);
        }
      }catch(err){
        console.log(err);
      }
    },

    async unlinkMachine(){
      try{

        if (!await this.confirmModal(`장비 '${this.machine.mid}' 연결을 해제합니다. 진행하시겠습니까?`, '장비 연결 해제')) {
          return await this.alertModal('장비 연결헤제가 취소되었습니다', '장비정보', 'warning');
        }

        let tankParam = {
          machine: null,
          mid: ''
        }

        let machineParam = {
          tank: null
        }

        const r = await apiCall('put', `/api/tank/${this.tankId}`, tankParam);
        console.log('unlinkMachine result ---------------tank---->',r);
        if (r.code === 200) {
          const rr = await apiCall('put', `/api/machine/${this.machine._id}`, machineParam);
          this.machine = null;
          this.$emit('update:machineId', null);
          // this.machineId = this.machine._id
          console.log('unlinkMachine result ---------------machine---->',rr);
          await this.getMachineOpts();

        } else {
          await this.alertDanger('장비 연결 해제 실패: ' + r.message);
        }
    }catch(err){
      console.log(err);
    }
    },

    async createMachine(){
      let newMachine;
      try{

        if( !await this.confirmModal(`신규 장비를 등록합니다. 진행 하시겠습니까?`, '장비정보 등록') ){
          await this.alertModal( '장비 등록 취소', '장비정보', 'warning');
          return;
        }else{
          newMachine = cloneVar(MachineSchema);
        }

        if(this.selectedMachineId!==null){
          if( await this.confirmModal(`장비 아이디 '${this.selectedMachineId.mid}' 정보를 복제하여 등록합니다. 진행하시겠습니까?`, '탱크정보 복제') ){
            newMachine = cloneVar(this.selectedMachineId); // 복제등록 _id 제거, 제거안하면 duplication error
            delete newMachine._id;
          }
        }

        newMachine.mid = getRandomHex(4);
        newMachine.name = 'ATG-'+newMachine.mid;
        newMachine.modelName = 'NEXUA-3011';

        const r = await apiCall('post', `/api/machine`, newMachine);

        if( r.code===200){
          await this.alertModal( `장비(${newMachine.mid})가 등록 되었습니다`, '장비정보 등록', 'success');
          await this.getMachineOpts();
        }else{
          await this.alertModal('장비정보 등록 실패: '+r.message,'장비정보 등록 실패', 'warning');
        }

      }catch(err){
        console.log( err );
        await this.alertModal('장비정보 등록 실패:'+err.message,'장비정보 등록 오류', 'danger');
      }

    },

    async saveMachine(){
      let rs = { code: 999, message: '', result: false };
      this.isUpdatingRecord = true;
      try{
        // const row = this.$refs['excelGrid'].currentRecord;
        const rec = this.machine;
        console.log( 'param --->', rec);
        rs = await apiCall("PUT", `/api/machine/${rec._id}`, rec);
        console.log( 'result ----------->', rs );
        // this.machine = rs.result;

        await sleep(1000);
        await this.toastResult(rs, `장비정보 수정`);

      }catch(err){
        await this.alertDanger(err.message, rs.code, 'danger');
        console.log( err );
      }finally{
        this.isUpdatingRecord = false;
      }
    },

  }
}
</script>
